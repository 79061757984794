import React from "react";
import Image from "next/image";
import Link from "next/link";
import NewsActionButtons from "./news-action-buttons";
import useDateLocale from "hooks/useDateLocale";
import { NewsSummaryFragment } from "@graphql/generated/graphql";
import { Trans } from "@lingui/macro";
import { formatDistanceToNowStrict } from "date-fns";

interface INewsCardProps {
  news: NewsSummaryFragment;
  big?: boolean;
  showPublisher?: boolean;
}

const NewNewsCard: React.FunctionComponent<INewsCardProps> = ({
  news,
  showPublisher,
}) => {
  return (
    <Link href={`/news/${news.id}`} passHref>
      <a>
        <div className="lg:hidden">
          <CompactNewsCard news={news} showPublisher={showPublisher} />
        </div>
        <div className="hidden lg:block">
          <WideNewsCard news={news} showPublisher={showPublisher} />
        </div>
      </a>
    </Link>
  );
};

const CompactNewsCard: React.FunctionComponent<INewsCardProps> = ({
  news,
  showPublisher,
}) => {
  const { thumbnailUrl, title, newsPublisher, pubDate, good } = news;
  const locale = useDateLocale();

  return (
    <div className="flex">
      <div className="mr-4 flex w-full flex-col justify-between">
        <div className="flex-col">
          <p className="mb-2 flex pr-2 font-poppins text-[14px] font-bold leading-5 text-text-darkest hover:text-text-dark 2xs:text-[16px]">
            {title}
          </p>
          <div className="flex flex-row items-center space-x-2">
            {showPublisher && (
              <Link href={`/publishers/${newsPublisher.slug}`} passHref>
                <a>
                  <p className="font-sen text-[10px] font-bold text-text-lightest 2xs:text-xs">
                    {newsPublisher.name}
                  </p>
                </a>
              </Link>
            )}
            <p className="flex-1 font-sen text-[10px] text-disabled 2xs:text-xs">
              {formatDistanceToNowStrict(new Date(pubDate), {
                locale,
                addSuffix: false,
              })}
            </p>
          </div>

          {good && (
            <div className="my-1 flex">
              <GoodNewsLabel />
            </div>
          )}
        </div>
        <NewsActionButtons news={news} />
      </div>
      <div className="flex flex-col items-end">
        <div className="relative h-[110px] w-[105px]">
          <Image
            className="rounded-lg "
            src={thumbnailUrl}
            alt={title}
            layout="fill"
            objectFit="cover"
          />
        </div>

        {showPublisher && (
          <div
            className="relative -mt-6 h-8 w-8"
            style={{
              filter: "drop-shadow(1px 1px 8px rgba(147, 154, 188, 0.4)",
            }}
          >
            <Image
              className="rounded-lg"
              src={newsPublisher.iconUrl}
              alt={newsPublisher.name}
              layout="fill"
              objectFit="cover"
            />
          </div>
        )}
      </div>
    </div>
  );
};

const WideNewsCard: React.FunctionComponent<INewsCardProps> = ({
  news,
  showPublisher,
}) => {
  const { thumbnailUrl, title, newsPublisher, pubDate, good } = news;
  const locale = useDateLocale();

  return (
    <div className="flex">
      <div className="flex flex-1 flex-col justify-between pr-6">
        <div className="font-bold text-text-darkest">
          <p className="mb-3 font-poppins text-xl font-bold hover:text-text-dark">
            {title}
          </p>

          <div className="flex flex-row items-center space-x-4">
            {showPublisher && (
              <Link href={`/publishers/${newsPublisher.slug}`}>
                <a>
                  <p className="font-sen text-sm font-light text-text-dark">
                    {newsPublisher.name}
                  </p>
                </a>
              </Link>
            )}
            <p className="font-sen text-xs font-light text-text-lightest">
              {formatDistanceToNowStrict(new Date(pubDate), {
                locale: locale,
                addSuffix: false,
              })}
            </p>
          </div>

          {good && (
            <div className="my-1 flex font-sen font-light">
              <GoodNewsLabel />
            </div>
          )}
        </div>
        <NewsActionButtons news={news} />
      </div>
      <div className="flex flex-col items-end">
        <div className="relative h-44 w-56">
          <Image
            className="rounded-lg "
            src={thumbnailUrl}
            alt={title}
            layout="fill"
            objectFit="cover"
          />
        </div>

        {showPublisher && (
          <div
            className="relative -mt-10 -ml-2 h-[48px] w-[76px]"
            style={{
              filter: "drop-shadow(1px 1px 8px rgba(147, 154, 188, 0.4)",
            }}
          >
            <Image
              className="rounded-lg"
              src={newsPublisher.imageUrl}
              alt={newsPublisher.name}
              layout="fill"
              objectFit="cover"
            />
          </div>
        )}
      </div>
    </div>
  );
};

const GoodNewsLabel: React.FunctionComponent<{}> = (props) => {
  return (
    <div className="flex items-center justify-center rounded-[4px] bg-green px-2 py-0.5">
      <span className="font-sen text-xs text-white">
        <Trans>Good News 🎉</Trans>
      </span>
    </div>
  );
};

export { WideNewsCard, CompactNewsCard };
export default NewNewsCard;
